import React, { useEffect, useRef, useState } from 'react';

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { m } from 'framer-motion';

// Components
import { Header, HeaderNav, Menu } from '../Components/Header/Header';
import Buttons from '../Components/Button/Buttons';
import { fadeIn } from '../Functions/GlobalAnimations';

import SocialIcons from '../Components/SocialIcon/SocialIcons';

const SocialIconsData = [
  {
    color: '#828282',
    link: 'https://www.instagram.com/broadwayhotpot/',
    icon: 'fab fa-instagram',
  },
  // {
  //   color: '#828282',
  //   link: 'https://www.yelp.com/',
  //   icon: 'fab fa-yelp',
  // },
  {
    color: '#828282',
    link: 'https://www.xiaohongshu.com/user/profile/5c783ddb0000000017011fbf',
    alt: 'xiaohongshu',
  },
];

const AboutUs = (props) => {
  const [language, setLanguage] = useState('EN');

  // actual site
  useEffect(() => {
    let links = document.querySelectorAll('.section-link');

    links.forEach((item) => {
      item.addEventListener('click', () => {
        document.querySelector('.navbar-toggler:not(.collapsed)')?.click();
      });
    });
  }, []);

  const historyRef = useRef(null);
  const aboutUsRef = useRef(null);

  const handleNavClick = (to) => {
    switch (to) {
      case '/#':
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        break;
      case '/':
        window.location.href = '/'
        break;
      case '/#history':
        historyRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case '/#about-us':
        aboutUsRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  };
  const [showHotpotHistory, setShowHotpotHistory] = useState(false);
  const [showFellsHistory, setShowFellsHistory] = useState(false);

  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ desktop: true }}
        type="reverse-scroll border-b border-[#ffffff1a] bg-white"
      >
        <HeaderNav
          theme="white"
          fluid="fluid"
          className="py-[0px] px-[35px] md:py-[15px] md:pr-[15px] sm:px-0 md:pl-0"
          containerClass="xs:pr-0"
        >
          <Col
            className="col-8 col-lg-2 me-auto ps-lg-0 xs:!p-0"
            style={{ display: 'flex', alignItems: 'center', gap: 10 }}
          >
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
            <div
              onClick={() =>
                setLanguage((prev) => (prev === 'EN' ? 'CN' : 'EN'))
              }
              style={{ cursor: 'pointer' }}
              ariaLabel={
                language === 'EN' ? '切换中文' : 'Translate to English'
              }
            >
              {language === 'EN' ? '中' : 'EN'}
            </div>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px] xs:mr-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-end col-auto menu-order px-lg-0">
            <Menu
              {...props}
              historyRef={historyRef}
              aboutUsRef={aboutUsRef}
              socialIconsData={SocialIconsData}
              language={language}
            />
          </Navbar.Collapse>
          <Col className="col-auto text-end pe-0 font-size-0 !pl-[22px] xs:hidden">
            <SocialIcons
              theme="social-icon-style-01 block text-center"
              iconColor="dark"
              size="xs"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}

      {/* Hotpot History Start */}
      <section
        ref={historyRef}
        className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat bg-cover overflow-hidden relative bg-center"
      >
        <Container>
          <Row className="items-center">
            <Col
              xl={4}
              lg={5}
              md={{ span: 6, order: 1 }}
              className="order-2 pr-0 xs:pr-[15px]"
            >
              <h2 className="heading-5 font-serif text-darkgray uppercase mb-[25px]">
                {language === 'EN' ? 'Welcome to' : '欢迎光临'}{' '}
                <span className="font-bold">
                  {language === 'EN'
                    ? 'Broadway Hotpot & Bar'
                    : '百老汇火锅酒吧'}
                </span>
              </h2>
              <blockquote className="border-l-[4px] border-basecolor text-xmd text-darkgray font-medium py-[2px] pl-[25px] pr-0 xs:mt-[30px] mt-[40px] mb-[30px] w-[85%] md:w-full">
                {language === 'EN'
                  ? 'One should not attend even the end of the world without a good hotpot'
                  : '即使世界末日，没有一顿好火锅也不值得赴约'}
              </blockquote>
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Welcome to Broadway Hotpot & Bar, where we bring the authentic
                  flavors of Chinese hotpot to our community! At the heart of
                  Chinese culinary tradition, hotpot is more than just a meal —
                  it's a communal experience that brings friends and family
                  together. With a rich history dating back over a thousand
                  years, Chinese hotpot features a simmering pot of broth, where
                  you can cook a variety of fresh ingredients like sliced meats,
                  vegetables, seafood, and tofu to your perfection.
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  欢迎光临百老汇火锅酒吧！我们致力于将正宗的中国火锅美味带给我们的社区。
                  火锅作为中国饮食文化的精髓，不仅是一顿丰盛的美食，更是一种让亲友团聚的温馨体验。拥有上千年历史的火锅，以滚烫的汤底为核心，您可以在其中涮煮各类新鲜食材，如肉片、蔬菜、海鲜和豆腐，随心定制您的独特美味。
                </p>
              )}
              {language === 'EN' && showHotpotHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Choose from a variety of flavorful broths, ranging from the
                  bold and spicy Sichuan style to the light and aromatic herbal
                  broths. Customize your meal with an assortment of delicious
                  dipping sauces, making each bite a unique and satisfying
                  experience. At Broadway Hotpot & Bar, we are dedicated to
                  providing you with an authentic and enjoyable hotpot dining
                  experience, filled with warmth, flavor, and the joy of sharing
                  great food.
                </p>
              )}
              {language === 'CN' && showHotpotHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  在百老汇火锅酒吧，您可以选择多种风味浓郁的汤底，从麻辣鲜香的四川风味，到清淡芳香的药膳汤底，应有尽有。搭配丰富多样的蘸料，让每一口都充满独特美味，带来难忘的满足感。我们致力于为您提供正宗且愉悦的火锅体验，在温暖的氛围中感受美味与分享的乐趣。
                </p>
              )}
              <Buttons
                ariaLabel={
                  showHotpotHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                className="btn-fill btn-fancy tracking-[1px] font-medium font-serif rounded-sm uppercase md:mb-[15px]"
                themeColor="#cd9452"
                color="#fff"
                size="md"
                title={
                  showHotpotHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                onClick={() => {
                  setShowHotpotHistory(!showHotpotHistory);
                }}
                type="button"
              />
            </Col>
            <Col
              lg={7}
              xl={{ offset: 1 }}
              md={{ span: 6, order: 2 }}
              className="order-1 md:mb-20"
            >
              <m.img
                width="661.5px"
                height="575.2px"
                src="/assets/img/hotpot-history.png"
                loading="lazy"
                alt="cafe"
                data-no-retina=""
                {...fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Hotpot History End */}

      {/* Building History Start */}
      <section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat bg-cover overflow-hidden relative bg-center">
        <Container>
          <Row className="items-center">
            <Col
              xl={4}
              lg={5}
              md={{ span: 6, order: 1 }}
              className="order-2 pr-0 xs:pr-[15px]"
            >
              <h2 className="heading-5 font-serif text-darkgray uppercase mb-[25px]">
                {language === 'EN'
                  ? 'Located in the Heart of Fells Point'
                  : '坐落于Fells Point的中心'}
              </h2>
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Fells Point is one of Baltimore's most historic neighborhoods,
                  rich in maritime heritage and cultural diversity. Nestled
                  along the waterfront, the area have long been a hub of trade
                  and commerce, dating back to the 18th century.
                </p>
              )}
              {language === 'EN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  The building Broadway Hotpot & Bar located was once a German
                  immigrant shoe maker's home in 18th century. During restaurant
                  renovation, the descendants of that family visited us and
                  shared their family story. This connection to the past has
                  strengthened our dedication to preserving and celebrating
                  cultural traditions through food
                </p>
              )}
              {language === 'EN' && showFellsHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Just to the east, Canton emerged as an important part of
                  Baltimore's growing trade network. The neighborhood's name is
                  a nod to its connection to the Far East, the Chinese port of
                  Guangzhou, historically called Canton by English speakers.
                  This historic link to China, serves as an inspiration for our
                  restaurant, where we bring a taste of authentic Chinese hotpot
                  to the heart of Baltimore.
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  费尔斯角是巴尔的摩最具历史意义的社区之一，拥有丰富的海洋遗产和多元文化。这个沿水而建的区域自18世纪以来一直是贸易和商业的中心。
                </p>
              )}
              {language === 'CN' && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  Broadway Hotpot &
                  Bar所在的建筑，原本是18世纪一位德国移民鞋匠的住所。在餐厅装修期间，这家族的后代还专程前来拜访，与我们分享了他们的家族故事。这段历史联系加深了我们对文化传统的敬意，也激励我们通过美食来传承和庆祝多样的文化。
                </p>
              )}
              {language === 'CN' && showFellsHistory && (
                <p className="w-[90%] md:w-full mb-[35px]">
                  在Fells
                  Point以东不远的Canton，曾是巴尔的摩贸易网络的重要组成部分。该社区的名字呼应了其与远东的联系，源自中国的广州港，历史上被英语世界称为“Canton”。这种与中国的历史渊源为我们的餐厅带来了灵感，让我们得以将地道的中式火锅引入巴尔的摩的核心地带。
                </p>
              )}
              <Buttons
                ariaLabel={
                  showFellsHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                className="btn-fill btn-fancy tracking-[1px] font-medium font-serif rounded-sm uppercase md:mb-[15px]"
                themeColor="#cd9452"
                color="#fff"
                size="md"
                title={
                  showFellsHistory
                    ? language === 'EN'
                      ? 'show less'
                      : '收起'
                    : language === 'EN'
                    ? 'explore more'
                    : '了解更多'
                }
                onClick={() => {
                  setShowFellsHistory(!showFellsHistory);
                }}
                type="button"
              />
            </Col>
            <Col
              lg={7}
              xl={{ offset: 1 }}
              md={{ span: 6, order: 2 }}
              className="order-1 md:mb-20"
            >
              <m.img
                width="661.5px"
                height="575.2px"
                src="/assets/img/building-history.png"
                loading="lazy"
                alt="cafe"
                data-no-retina=""
                {...fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Building History End */}

      {/* Footer Start */}
      <footer className="text-[#828282] py-[60px] border-t border-mediumgray md:pt-[80px] md:pb-[60px]">
        <Container>
          <Row className="items-center">
            <Col md={6} className="sm:mb-[15px]">
              <ul className="flex text-sm font-serif text-center justify-start md:justify-start sm:justify-center xs:text-center xs:flex-col">
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Home Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/');
                    }}
                  >
                    {language === 'EN' ? 'Home' : '主页'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="About Us Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#');
                    }}
                  >
                    {language === 'EN' ? 'About Us' : '关于我们'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Menu Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#menu');
                    }}
                  >
                    {language === 'EN' ? 'Menu' : '菜单'}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Reservation Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleNavClick('/#reservation');
                    }}
                  >
                    {language === 'EN' ? 'Reservation' : '预约'}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={6} className="text-center">
              <p className="text-sm uppercase flex items-center justify-center">
                © {new Date().getFullYear()} Broadway Hotpot
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </>
  );
};

export default AboutUs;

import React, { useEffect, useRef, useState } from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { m } from "framer-motion";

// Components
import { Header, HeaderNav, Menu } from "../Components/Header/Header";
import Buttons from "../Components/Button/Buttons";
import { fadeIn } from "../Functions/GlobalAnimations";

import SocialIcons from "../Components/SocialIcon/SocialIcons";

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://www.instagram.com/broadwayhotpot/",
    icon: "fab fa-instagram",
  },
  // {
  //   color: '#828282',
  //   link: 'https://www.yelp.com/',
  //   icon: 'fab fa-yelp',
  // },
  {
    color: "#828282",
    link: "https://www.xiaohongshu.com/user/profile/5c783ddb0000000017011fbf",
    alt: "xiaohongshu",
  },
];

const HotpotHistory = (props) => {
  const [language, setLanguage] = useState("EN");

  // actual site
  useEffect(() => {
    let links = document.querySelectorAll(".section-link");

    links.forEach((item) => {
      item.addEventListener("click", () => {
        document.querySelector(".navbar-toggler:not(.collapsed)")?.click();
      });
    });
  }, []);

  const historyRef = useRef(null);
  const aboutUsRef = useRef(null);

  const handleNavClick = (to) => {
    switch (to) {
      case "/#":
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        break;
      case "/":
        window.location.href = "/";
        break;
      case "/#history":
        historyRef.current.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case "/#about-us":
        aboutUsRef.current.scrollIntoView({
          behavior: "smooth",
        });
        break;
      default:
        break;
    }
  };
  const [showHotpotHistory, setShowHotpotHistory] = useState(false);

  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ desktop: true }}
        type="reverse-scroll border-b border-[#ffffff1a] bg-white"
      >
        <HeaderNav
          theme="white"
          fluid="fluid"
          className="py-[0px] px-[35px] md:py-[15px] md:pr-[15px] sm:px-0 md:pl-0"
          containerClass="xs:pr-0"
        >
          <Col
            className="col-8 col-lg-2 me-auto ps-lg-0 xs:!p-0"
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  loading="lazy"
                  src="/assets/img/logo.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
            {/* <div
              onClick={() =>
                setLanguage((prev) => (prev === "EN" ? "CN" : "EN"))
              }
              style={{ cursor: "pointer" }}
              ariaLabel={
                language === "EN" ? "切换中文" : "Translate to English"
              }
            >
              {language === "EN" ? "中" : "EN"}
            </div> */}
          </Col>
          <Navbar.Toggle className="order-last md:ml-[25px] sm:ml-[17px] xs:mr-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-end col-auto menu-order px-lg-0">
            <Menu
              {...props}
              historyRef={historyRef}
              aboutUsRef={aboutUsRef}
              socialIconsData={SocialIconsData}
              language={language}
            />
          </Navbar.Collapse>
          <Col className="col-auto text-end pe-0 font-size-0 !pl-[22px] xs:hidden">
            <SocialIcons
              theme="social-icon-style-01 block text-center"
              iconColor="dark"
              size="xs"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}

      {/* Hotpot History Start */}
      <section
        ref={historyRef}
        className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] bg-no-repeat bg-cover overflow-hidden relative bg-center"
      >
        <Container>
          <Row className="items-center">
            <Col
              xl={4}
              lg={5}
              md={{ span: 6, order: 1 }}
              className="order-2 pr-0 xs:pr-[15px]"
            >
              <h2 className="heading-5 font-serif text-darkgray uppercase mb-[25px]">
                Chinese Hot Pot: A history and how-to
              </h2>
              {language === "EN" && (
                <>
                  <p className="w-[90%] md:w-full mb-[35px]">
                    The concept of Chinese Hot pot (huǒ guō) is believed to date
                    back more than 1,000 years to the time of the Jin Dynasty.
                    Hot pot's roots can be found in the dining practices of
                    Mongolian horsemen who rode across the steppe and into
                    northern China. Legend has it that the Mongols used their
                    helmets as vessels to simmer broth over open fires, and
                    cooked chunks of meat in that broth. And their shields? They
                    were used as a sort of frying pan to sear meat, of course.
                    Mongol horseman knew how to pack light.
                  </p>
                  <p className="w-[90%] md:w-full mb-[35px]">
                    China resisted the Mongols. Heck, they even built the Great
                    Wall to keep them out, which despite its splendour did not
                    succeed. However, the Chinese took a little bit of the good
                    with the bad and incorporated this style of eating,
                    modifying it over the years for themselves. In the centuries
                    that witnessed the growth of the Song Dynasty, hot pot moved
                    — and morphed — further south into China, with each
                    successive region adapting it to their local ingredients and
                    tastes. Sichuan hot pot in Chengdu with all its glorious
                    peppers and spices.
                  </p>
                </>
              )}
              {language === "EN" && showHotpotHistory && (
                <>
                  <p className="w-[90%] md:w-full mb-[35px]">
                    Perhaps the most famous hot pot variation is the Chongqing
                    or Sichuan variety. This style features a dark-red broth
                    chock full (and often bristling) with the bits of 20 spices,
                    chili peppers, and the uniquely mouth-numbing Sichuan pepper
                    (huā jiāo). In Beijing, and elsewhere in the north, hot pot
                    broth tends to be on the mild side, and compared to its racy
                    southern cousins, a little bland. In Manchuria, a sort of
                    local fermented sauerkraut is used to add some tang, making
                    the broth a bit sour.
                  </p>
                  <p className="w-[90%] md:w-full mb-[35px]">
                    Over the course of time, through the machinery of China’s
                    diverse culture and culinary methods, hot pot spread
                    throughout the country and transformed from a treat enjoyed
                    at home in winter to almost staple status, eaten all year
                    round. You can find it now in establishments that range from
                    street-side hot pot stands to formal indoor affairs. And
                    while regional differences apply and prevail, today you can
                    usually find any style of hot pot you like almost anywhere
                    in China, particularly in larger cities.
                  </p>
                  <p className="w-[90%] md:w-full mb-[35px]">
                    By now, you've probably figured out that Chinese hot pot at
                    its most basic is a straightforward concept. When you enter
                    into a hot pot restaurant, your steps to hot pot bliss will
                    roughly track the following process:
                  </p>
                </>
              )}
              <Buttons
                ariaLabel={
                  showHotpotHistory
                    ? language === "EN"
                      ? "show less"
                      : "收起"
                    : language === "EN"
                    ? "explore more"
                    : "了解更多"
                }
                className="btn-fill btn-fancy tracking-[1px] font-medium font-serif rounded-sm uppercase md:mb-[15px]"
                themeColor="#cd9452"
                color="#fff"
                size="md"
                title={
                  showHotpotHistory
                    ? language === "EN"
                      ? "show less"
                      : "收起"
                    : language === "EN"
                    ? "explore more"
                    : "了解更多"
                }
                onClick={() => {
                  setShowHotpotHistory(!showHotpotHistory);
                }}
                type="button"
              />
            </Col>
            <Col
              lg={7}
              xl={{ offset: 1 }}
              md={{ span: 6, order: 2 }}
              className="order-1 md:mb-20"
            >
              <m.img
                width="661.5px"
                height="575.2px"
                src="/assets/img/hotpot-history.png"
                loading="lazy"
                alt="cafe"
                data-no-retina=""
                {...fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Hotpot History End */}

      {/* Footer Start */}
      <footer className="text-[#828282] py-[60px] border-t border-mediumgray md:pt-[80px] md:pb-[60px]">
        <Container>
          <Row className="items-center">
            <Col md={6} className="sm:mb-[15px]">
              <ul className="flex text-sm font-serif text-center justify-start md:justify-start sm:justify-center xs:text-center xs:flex-col">
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Home Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleNavClick("/");
                    }}
                  >
                    {language === "EN" ? "Home" : "主页"}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="About Us Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleNavClick("/about-us");
                    }}
                  >
                    {language === "EN" ? "About Us" : "关于我们"}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Menu Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleNavClick("/#menu");
                    }}
                  >
                    {language === "EN" ? "Menu" : "菜单"}
                  </a>
                </li>
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mr-0 xs:mb-[7px]">
                  <a
                    aria-label="Reservation Link"
                    className="hover:text-[#ca943d]"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleNavClick("/#reservation");
                    }}
                  >
                    {language === "EN" ? "Reservation" : "预约"}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={6} className="text-center">
              <p className="text-sm uppercase flex items-center justify-center">
                © {new Date().getFullYear()} Broadway Hotpot
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </>
  );
};

export default HotpotHistory;

const HeaderData = [
  {
    title: 'Home',
    cnTitle: '主页',
    link: '/',
  },
  {
    title: 'About Us',
    cnTitle: '关于我们',
    dropdown: [{
      link: '/about-us',
      title: 'Our Story'
    },
    {
      link: '/hotpot-history',
      title: 'Hotpot History & How-to'
    }],
  },
  {
    title: 'Menu',
    cnTitle: '菜单',
    link: '/#menu',
  },
  {
    title: 'Karaoke Room',
    cnTitle: '卡拉OK',
    link: '/#karaoke-room',
  },
  // {
  //   title: 'Royalty',
  //   cnTitle: '会员',
  //   link: '/#royalty-program',
  // },
  {
    title: 'Reservation',
    cnTitle: '预约',
    link: '/#reservation',
  },
  {
    title: 'Find Us',
    cnTitle: '联系我们',
    link: '/#find-us',
  },
];

export default HeaderData;
